import React, { useContext } from 'react';
import { Button, Modal } from 'antd';
import { GlobalContext } from '../../contexts/GlobalStateContext';

const ModalAccessMenu = () => {
    const { openModalAccessMenu, setOpenModalAccessMenu } = useContext(GlobalContext);
    return (
        <Modal
            title={<span className="sl-font-semibold sl-text-xl">Access Menu</span>}
            centered
            open={openModalAccessMenu}
            onCancel={() => setOpenModalAccessMenu(false)}
            footer={null}
            width="400px"
            className="sl-my-5"
        >
            <div className="sl-py-5 sl-flex sl-justify-center">
                <span>Anda tidak memiliki akses menu yang anda tuju!</span>
            </div>
            <div className="sl-pt-4 sl-flex sl-gap-2 sl-justify-end">
                <Button
                    className="sl-font-semibold"
                    onClick={() => setOpenModalAccessMenu(false)}
                >
                    Kembali
                </Button>
            </div>
        </Modal>
    );
};

export default ModalAccessMenu;
