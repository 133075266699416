import React from 'react'

export default function KeyIcon({ className }) {
  return (
    <svg 
      className={className}
      width="20" 
      height="20" 
      viewBox="0 0 20 20" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        d="M5.83317 14.1666C7.84484 14.1666 9.529 12.7325 9.91484 10.8333H11.6665V12.5H13.3332V10.8333H14.9998V13.3333H16.6665V10.8333H17.4998V9.16665H9.91484C9.529 7.26748 7.84484 5.83331 5.83317 5.83331C3.53567 5.83331 1.6665 7.70248 1.6665 9.99998C1.6665 12.2975 3.53567 14.1666 5.83317 14.1666ZM5.83317 7.49998C7.2115 7.49998 8.33317 8.62165 8.33317 9.99998C8.33317 11.3783 7.2115 12.5 5.83317 12.5C4.45484 12.5 3.33317 11.3783 3.33317 9.99998C3.33317 8.62165 4.45484 7.49998 5.83317 7.49998Z" 
        fill="currentColor"
      />
    </svg>
  );
}
