import React, { useContext, useEffect, useState } from "react"
import { Button, Input, Modal, Pagination,  Select, Table, Space } from "antd"
import SearchIcon from "../../assets/icons/SearchIcon.js"
import MoreIcon from "../../assets/icons/MoreIcon.js"
import { NavLink, useSearchParams } from "react-router-dom"
import AddIcon from "../../assets/icons/AddIcon.js"
import ModalDetailRole from '../../components/RoleManagement/ModalDetailRole.js'
import { useQuery } from 'react-query'
import { deleteRole, getListRole } from '../../api/RoleManagement.js'
import { GlobalContext } from '../../contexts/GlobalStateContext.js'
import { toast } from "react-toastify"

export default function RoleManagementList() {
    const { accessBased } = useContext(GlobalContext);
    const [idRole, setIdRole] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const page = parseInt(searchParams.get("page") || "1", 10);
    const pageSize = parseInt(searchParams.get("pageSize") || "10", 10);
    const sortField = searchParams.get("sortField") || "";
    const sortOrder = searchParams.get("sortOrder") || "";
    const search = searchParams.get("search") || "";

    const [modalPop, setModalPop] = useState()
    const [modalRole, setModalRole] = useState({ show: false })

    const [openModalDetailRole, setOpenModalDetailRole] = useState(false);

    const handleEditRole = (id) => {
        window.location.href = `/role-management/edit-role/${id}`;
    };

    const { data, refetch } = useQuery(
        ['getListRole', { page, pageSize, sortField, sortOrder, search }],
        () => getListRole({
            search,
            page,
            limit: pageSize,
            sort: sortOrder && sortOrder === "descend" ? `-${sortField}` : sortField
        }),
        {
            enabled: true,
        }
    );

    const columns = [
        {
            title: "Role Name",
            dataIndex: "role",
            key: "role",
            sorter: () => null,
            showSorterTooltip: false
        },
        {
            title: "Menu",
            dataIndex: "menu",
            key: "menu",
            sorter: () => null,
            render: (_, record) => (
                <>
                    <div className="sl-w-full sl-flex sl-flex-wrap sl-gap-2">
                        {record.menu?.map((menuItem, index) => (
                            <span
                                key={index}
                                className="sl-w-auto sl-font-semibold sl-text-base sl-bg-border-primary-light sl-p-1 sl-rounded-md"
                            >
                                {menuItem}
                            </span>
                        ))}
                    </div>
                </>
            )
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
            render: (_, record) => (
                <div className="sl-flex sl-items-center sl-justify-end sl-gap-2 sl-relative">
                    <Button
                        className="sl-font-semibold"
                        onClick={() => {
                            setOpenModalDetailRole(!openModalDetailRole);
                            setIdRole(record?.id);
                        }}
                    >
                        DETAILS
                    </Button>
                    {accessBased?.roleManagement?.can_update 
                        && accessBased?.roleManagement?.can_delete && (
                        <Button
                            className="sl-font-semibold sl-p-2"
                            onClick={() => setModalPop(modalPop ? null : record.key)}
                            onBlur={() =>
                                setTimeout(() => {
                                    setModalPop()
                                }, 300)
                            }
                        >
                            <Space>
                                <MoreIcon />
                            </Space>
                        </Button>

                    )}
                    {modalPop === record.key && (
                        <div
                            className={`sl-absolute sl-right-12 sl-bg-white sl-shadow-lg sl-z-30 sl-flex sl-flex-col sl-w-32 sl-cursor-pointer sl-p-2`}
                        >
                            {accessBased?.roleManagement?.can_update && (
                                <div
                                    className="hover:sl-bg-[lightgray] sl-p-2"
                                    onClick={() => {
                                        setModalPop()
                                        handleEditRole(record?.id);
                                    }}
                                >
                                    Edit
                                </div>
                            )}

                            {accessBased?.roleManagement?.can_delete && (
                                <div
                                    className="hover:sl-bg-[lightgray] sl-p-2 sl-border-t sl-border-[lightgray]"
                                    onClick={() => {
                                        setModalPop()
                                        setModalRole({ data: modalPop, show: true, type: "Delete" })
                                        setIdRole(record?.id);
                                    }}
                                >
                                    Delete
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )
        }
    ];

    const datas = data?.data?.map((item, index) => (
        {
            key: index,
            id: item?.id,
            role: item?.role,
            menu: item?.menus 
                    ? item?.menus?.map((item) => item?.name)
                    : []
        }
    ));
    
    const roleManagementMeta = data?.meta
    
    const updateSearchParams = (params) => {
        setSearchParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams)
            Object.entries(params).forEach(([key, value]) => {
                if (value) {
                    newParams.set(key, value)
                } else {
                    newParams.delete(key)
                }
            })

            if (params.sortField && !params.sortOrder) {
                newParams.delete("sortField")
            }
            return newParams
        })
    }

    const handleTableChange = (pagination, filters, sorter) => {
        updateSearchParams({
            sortField: sorter.field,
            sortOrder: sorter.order
        })
    }

    const handlePaginationChange = (page, pageSize) => {
        updateSearchParams({ page, pageSize })
    }

    const handlePageSizeChange = (pageSize) => {
        updateSearchParams({ page: 1, pageSize })
    }

    const handleSearchChange = (e) => {
        updateSearchParams({ search: e.target.value })
    }

    useEffect(() => {
        if (!searchParams.get("page")) {
            setSearchParams({ page: 1, pageSize: 10 })
        }
    }, [searchParams, setSearchParams])

    const handleDeleteRole = async () => {
        try {
            const res = await deleteRole(idRole);
            if (res) {
                toast.success(`Role berhasil dihapus!`);
                refetch();
                setModalRole({ show: false });
            }
        } catch (error) {
            toast.error(error?.response?.data?.errors?.[0].message ?? "Gagal menghapus data role!");
        }
    };

    return (
        <>
            <div>
                <div className="sl-flex sl-items-center sl-gap-4 sl-mb-[32px]">
                    <div className="sl-text-2xl sl-font-semibold">Role Management</div>
                </div>

                <div>
                    <div className="sl-flex sl-items-center sl-justify-between">
                        <Input
                            value={search}
                            placeholder="Search"
                            suffix={<SearchIcon />}
                            className="sl-w-[200px] sl-mb-[16px]"
                            onChange={handleSearchChange}
                        />
                        {accessBased?.roleManagement?.can_create && (
                            <NavLink to={`/role-management/create-role`}>
                                <Button className="sl-bg-button-primary sl-text-white sl-font-medium">
                                    <AddIcon />
                                    <div>ADD ROLE</div>
                                </Button>
                            </NavLink>
                        )}
                    </div>
                    <Table
                        columns={columns}
                        dataSource={datas}
                        pagination={false}
                        // loading={isLoading || isFetching}
                        onChange={handleTableChange}
                        footer={() => (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <div>
                                    Display{" "}
                                    <Select
                                        className="sl-w-[80px]"
                                        value={pageSize}
                                        onChange={handlePageSizeChange}
                                        options={[
                                            { value: 10, label: "10" },
                                            { value: 20, label: "20" },
                                            { value: 50, label: "50" },
                                            { value: 100, label: "100" }
                                        ]}
                                    />{" "}
                                    of {roleManagementMeta?.total} data.
                                </div>
                                <Pagination
                                    current={page}
                                    pageSize={pageSize}
                                    total={roleManagementMeta?.total}
                                    onChange={handlePaginationChange}
                                />
                            </div>
                        )}
                    />
                </div>
                <Modal
                    // className="scroll-modal"
                    title={<div className="sl-text-xl">{modalRole?.type === "Delete" ? "Hapus Role" : "Detail"}</div>}
                    centered
                    open={modalRole?.show}
                    onCancel={() => setModalRole({ show: false })}
                    footer={null}
                    width="400px"
                >
                    <div>
                        <p>Anda yakin untuk menghapus Role ? Role yang anda hapus tidak dapat dipulihkan kembali</p>
                        <div className="sl-pt-4 sl-flex sl-gap-2 sl-justify-end">
                            <Button className="sl-font-semibold" onClick={() => setModalRole({ show: false })}>
                                Batal
                            </Button>
                            <Button 
                                onClick={handleDeleteRole}
                                className="sl-font-semibold sl-bg-button-primary sl-text-white"
                            >
                                Hapus
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
            <ModalDetailRole 
                idRole={idRole}
                openModalDetailRole={openModalDetailRole} 
                setOpenModalDetailRole={setOpenModalDetailRole} 
            />
        </>
    )
}
