import { axiosGlobal } from "../utils/AxiosConfig.js"

export const getListUser = async (params) => {
    try {
        const URL = `company-users`;
        const response = await axiosGlobal.get(URL, {
            params: params
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getDetailUser = async (id) => {
    try {
        const URL = `company-users/${id}`;
        const response = await axiosGlobal.get(URL);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const createUser = async (payload) => {
    try {
        const URL = `company-users`;
        const response = await axiosGlobal.post(URL, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const updateUser = async (id, payload) => {
    try {
        const URL = `company-users/${id}`;
        const response = await axiosGlobal.put(URL, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const deleteUser = async (id) => {
    try {
        const URL = `company-users/${id}`;
        const response = await axiosGlobal.delete(URL);
        return response?.data;
    } catch (error) {
        throw error;
    }
};