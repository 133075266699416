import React from 'react'

export default function GitCompareIcon({ className }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00846 1.66675C3.40013 1.66675 2.0918 2.97508 2.0918 4.58341C2.0918 5.90008 2.97513 7.00258 4.17513 7.36425V13.3309C4.1743 13.4801 4.19763 14.8151 5.15346 15.7734C5.74346 16.3667 6.53346 16.6667 7.50013 16.6667V18.3334L10.8335 15.8334L7.50013 13.3334V15.0001C5.98096 15.0001 5.8468 13.7217 5.8418 13.3334V7.36425C7.0418 7.00258 7.92513 5.90008 7.92513 4.58341C7.92513 2.97508 6.61596 1.66675 5.00846 1.66675ZM5.00846 5.83342C4.3193 5.83342 3.75846 5.27258 3.75846 4.58341C3.75846 3.89425 4.3193 3.33341 5.00846 3.33341C5.69763 3.33341 6.25846 3.89425 6.25846 4.58341C6.25846 5.27258 5.69763 5.83342 5.00846 5.83342ZM15.8418 12.6359V6.66425C15.8376 5.32592 14.9443 3.33341 12.5001 3.33341V1.66675L9.1668 4.16675L12.5001 6.66675V5.00008C14.0243 5.00008 14.1676 6.28258 14.1751 6.66675V12.6359C12.9751 12.9976 12.0918 14.1001 12.0918 15.4167C12.0918 17.0251 13.4001 18.3334 15.0085 18.3334C16.6168 18.3334 17.9251 17.0251 17.9251 15.4167C17.9251 14.1001 17.0418 12.9976 15.8418 12.6359ZM15.0085 16.6667C14.3193 16.6667 13.7585 16.1059 13.7585 15.4167C13.7585 14.7276 14.3193 14.1667 15.0085 14.1667C15.6976 14.1667 16.2585 14.7276 16.2585 15.4167C16.2585 16.1059 15.6976 16.6667 15.0085 16.6667Z"
        fill="currentColor"
      />
    </svg>
  );
}
