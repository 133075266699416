import { axiosGlobal } from "../utils/AxiosConfig.js"

export const getProfile = async () => {
    try {
        const URL = `me`;
        const response = await axiosGlobal.get(URL);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const updateProfile = async (payload) => {
    try {
        const URL = `me`;
        const response = await axiosGlobal.put(URL, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const forgotPassword = async (payload) => {
    try {
        const URL = `forgot-password`;
        const response = await axiosGlobal.post(URL, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const resetPassword = async (payload) => {
    try {
        const URL = `reset-password`;
        const response = await axiosGlobal.post(URL, payload);
        return response?.data;
    } catch (error) {
        throw error;
    }
};