import CryptoJS from 'crypto-js';

export const checkTokenAuth = () => {
  const keyToken = localStorage.getItem('token');

  if (keyToken) {
    const decryptedToken = CryptoJS.AES.decrypt(keyToken, 'key_token').toString(
      CryptoJS.enc.Utf8
    );

    return decryptedToken;
  }
};

export const checkRoleAuth = () => {
  const keyRole = localStorage.getItem('role');

  if (keyRole) {
    const decryptedRole = CryptoJS.AES.decrypt(keyRole, 'key_role').toString(
      CryptoJS.enc.Utf8
    );

    return decryptedRole;
  }
};

export const checkEmail = () => {
  const keyEmail = localStorage.getItem('email');

  if (keyEmail) {
    const decryptedRoleId = CryptoJS.AES.decrypt(
      keyEmail,
      'key_email'
    ).toString(CryptoJS.enc.Utf8);

    return decryptedRoleId;
  }
};

export const checkMenus = () => {
  const keyMenus = localStorage.getItem('menus');

  if (keyMenus) {
    try {
      // Decrypt the menus
      const decryptedString = CryptoJS.AES.decrypt(keyMenus, 'key_menus').toString(CryptoJS.enc.Utf8);

      // Parse the decrypted string into JSON
      const decryptedMenus = JSON.parse(decryptedString);

      return decryptedMenus;
    } catch (error) {
      console.error('Failed to decrypt or parse menus:', error);
      return null; // Return null if decryption or parsing fails
    }
  }

  return null; // Return null if no menus are found in localStorage
};

export const clearLocalStorageData = () => {
  localStorage.clear();
};