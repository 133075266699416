import { Layout } from "antd";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useParams, useNavigate } from "react-router-dom";

// import Logo from "../assets/images/logo.png";
import CloseIcon from "../assets/icons/CloseIcon";
import { checkMenus } from '../utils/AuthUtils';
import { GlobalContext } from '../contexts/GlobalStateContext';

const { Header, Content } = Layout;

export default function DetailLayout() {
  const location = useLocation();
  const { id } = useParams();
  const dataMenu = checkMenus();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { setOpenModalAccessMenu, setAccessBased } = useContext(GlobalContext);

  const [currentPage, setCurrentPage] = useState({
    title: '',
    backTo: '/'
  });

  useEffect(() => {
    if (location.pathname === "/price-estimation/create") {
      setCurrentPage({
        title: "CREATE ESTIMATION",
        backTo: "/price-estimation",
      });

      return;
    }

    // if (location.pathname === "/estimasi-daftar-harga/"+id) {
    //   setCurrentPage({
    //     title: "DETAIL ESTIMASI",
    //     backTo: "/price-estimation/create",
    //   });

    //   return;
    // }

    if (location.pathname.includes("/price-estimation") && !location.pathname.includes("/edit")) {
      setCurrentPage({
        title: "DETAIL ESTIMASI",
        backTo: "/price-estimation",
      });

      return;
    }

    if (location.pathname.includes("/price-estimation") && location.pathname.includes("/edit")) {
      setCurrentPage({
        title: "EDIT ESTIMASI",
        backTo: `/price-estimation/${id}`,
      });

      return;
    }

    if (
      location.pathname.includes("/invoice") && id
    ) {
      setCurrentPage({
        title: "INVOICE",
        backTo: "/invoice",
      });

      return;
    }

    if (location.pathname === "/role-management/create-role") {
      setCurrentPage({
        title: "CREATE ROLE",
        backTo: "/role-management",
      });

      return;
    }
    
    if (location.pathname === `/role-management/edit-role/${id}`) {
      setCurrentPage({
        title: "EDIT ROLE",
        backTo: "/role-management",
      });

      return;
    }
    
    if (location.pathname === `/evaluation/${id}`) {
      setCurrentPage({
        title: "EVALUATION",
        backTo: "/evaluation",
      });

      return;
    }
    
    if (location.pathname.includes("/price-estimation") && location.pathname.includes("/version")) {
      setCurrentPage({
        title: "DETAIL HISTORY ESTIMASI",
        backTo: `/price-estimation/${id}`,
      });

      return;
    }

    if (location.pathname.includes("/price-estimation/edit") && id) {
      setCurrentPage({
        title: "DETAIL ESTIMASI",
        backTo: "/price-estimation",
      });

      return;
    }

    setCurrentPage({
      title: "",
      backTo: "/price-estimation",
    });
  }, [location, id]);

  const sidebarMenu = dataMenu?.map((item) =>(
    {
      key: item?.id,
      label: item?.name,
      to: item?.frontend_endpoint,
    }
  ));

  useEffect(() => {
    const menuExists = sidebarMenu.find((e) => 
      e.to === pathname || pathname.startsWith(`${e.to}/`)
    );

    if (!menuExists) {
      // Redirect to the first valid menu item or another fallback URL
      setOpenModalAccessMenu(true);
      const fallbackMenu = sidebarMenu?.[0]?.to || '/'; // Default to the first menu item or "/"
      navigate(fallbackMenu); // Redirect to fallback
    }
  }, [sidebarMenu, pathname, navigate]);

  useEffect(() => {
    if(dataMenu){
      setAccessBased((prev) => ({
          ...prev,
          priceEstimation: dataMenu.find((e) => e.slug === "company-price-estimation" || null),
          masterData: dataMenu.find((e) => e.slug === "company-master-data" || null),
          invoice: dataMenu.find((e) => e.slug === "company-invoice" || null),
          evaluation: dataMenu.find((e) => e.slug === "company-evaluation") || null,
          userManagement: dataMenu.find((e) => e.slug === "company-user" || null),
          roleManagement: dataMenu.find((e) => e.slug === "company-role" || null),
      }));
    }
  }, []);

  return (
    <>
      <Layout style={{ minHeight: "100vh" }}>
        <Header className="sl-flex sl-items-center sl-justify-between sl-h-[48px] sl-py-0 sl-px-4 sl-fixed sl-w-full sl-z-[50]">
          <div className="sl-flex sl-items-center sl-text-white">
            <div className="sl-flex sl-items-center sl-gap-6">
              <NavLink to={currentPage.backTo}>
                <CloseIcon />
              </NavLink>
              <div className="sl-text-xl">{currentPage.title}</div>
            </div>
          </div>

          <div className="sl-flex sl-items-center sl-gap-4">
            {/* <img src={Logo} alt="Siloam Logo" className="sl-ml-2" /> */}
          </div>
        </Header>

        <Layout className="sl-pt-[48px]">
          <Content className="sl-bg-white">
            <Suspense fallback="...">
              <Outlet />
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
