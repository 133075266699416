import { axiosGlobal } from "../utils/AxiosConfig.js"

export const getListEvaluation = async (params) => {
    try {
        const URL = `evaluations`;
        const response = await axiosGlobal.get(URL, {
            params: params
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getHeaderEvaluation = async (params) => {
    try {
        const URL = `evaluations/header`;
        const response = await axiosGlobal.get(URL, {
            params: params
        });
        return response?.data;
    } catch (error) {
        throw error;
    }
};

export const getDetailEvaluation = async (id) => {
    try {
        const URL = `evaluations/${id}`;
        const response = await axiosGlobal.get(URL);
        return response?.data;
    } catch (error) {
        throw error;
    }
};