import React from 'react';
import { Button, Modal } from 'antd';
import { forgotPassword } from '../../api/Profile';
import { checkEmail } from '../../utils/AuthUtils';

const ModalConfirmChangePassword = ({ 
    openModalConfirmChangePassword, 
    setOpenModalConfirmChangePassword
}) => {
    const mail = checkEmail();

    const handleChangePassword = async () => {
        const email = {
            email: mail
        }
        try {
            const res = await forgotPassword(email);
            if(res){
                localStorage.removeItem("token");
                localStorage.removeItem("menus");
                localStorage.removeItem("role");
                localStorage.removeItem("name");
                window.location.href = `/verify-otp/${res?.data}`;
            }
        } catch (error) {
            console.log("error confirm change password", error);
            alert(
                error?.response?.data?.message ?? error?.response?.data?.errors[0]?.message ?? "Failed to submit data"
            )
        }
    };

    return (
        <Modal
            title={<span className="sl-font-semibold sl-text-xl">Change Password</span>}
            centered
            open={openModalConfirmChangePassword}
            onCancel={() => setOpenModalConfirmChangePassword(false)}
            footer={null}
            width="400px"
            className="sl-my-5"
        >
            <div className="sl-my-10 sl-flex sl-justify-center">
                <span>Apakah anda yakin untuk merubah kata sandi?</span>
            </div>
            <div className="sl-flex sl-gap-2 sl-justify-end">
                    <Button
                        className="sl-font-semibold"
                        onClick={() => setOpenModalConfirmChangePassword(false)}
                    >
                        Batal
                    </Button>
                    <Button 
                        onClick={handleChangePassword}
                        className="sl-font-semibold sl-bg-button-primary sl-text-white"
                    >
                        Ubah
                    </Button>
                </div>
        </Modal>
    );
};

export default ModalConfirmChangePassword;
