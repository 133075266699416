import React, { useEffect, useState } from 'react';
import { Modal, Button, Input } from 'antd';
import Label from '../Common/Label';
import { useQuery } from 'react-query';
import { getProfile, updateProfile } from '../../api/Profile';

const ModalUpdateProfile = ({ 
    refetch,
    openModalUpdateProfile, 
    setOpenModalUpdateProfile 
}) => {

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
    });

    const { data } = useQuery(
        ['getProfile' ],
        () => getProfile(),
        {
            enabled: true,
        }
    );

    useEffect(() => {
        setFormData((prev) => ({
            ...prev,
            name: data?.data?.name,
            phone: data?.data?.phone,
            email: data?.data?.email,
        }))
    }, [data, openModalUpdateProfile]);

    const handleChangeUser = (eventOrValue) => {
        if (eventOrValue?.target) {
            // Input Component
            const { name, value } = eventOrValue.target;
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));

            setErrors((prevErrors) => ({
                ...prevErrors,
                [name]: '', // Clear the error for this field
            }));
        } else {
            // Select Component
            setFormData((prevFormData) => ({
                ...prevFormData,
                role_id: eventOrValue, // Set the selected value
            }));

            setErrors((prevErrors) => ({
                ...prevErrors,
                role_id: '', // Clear the error for this field
            }));
        }
    };

    const validateEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Invalid email format! Please enter a valid email address.',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: '',
            }));
        }
    };

    const handleSubmitUser = async () => {
        setLoading(true);
        setErrors({});

        let hasErrors = false;
        const newErrors = {};

        Object.keys(formData).forEach((key) => {
            if (formData[key] === "") {
                newErrors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} tidak boleh kosong!`;
                hasErrors = true;
            }
        });

        setErrors(newErrors);

        if (hasErrors) {
            setLoading(false);
            return;
        };

        try {
            const res = await updateProfile(formData);
                
            if(res){
                refetch();
                setLoading(false);
                setOpenModalUpdateProfile(false);
            }
        } catch (error) {
            setLoading(false);
            setErrors((prev) => ({
                ...prev,
                error : error?.response?.data?.errors[0]?.message
            }));
            console.log("error update profile : ", error);
        }
    };

    const handleClose = () => {
        setOpenModalUpdateProfile(false);
        setErrors({});
    };

    return (
        <Modal
            title={<span className="sl-font-semibold sl-text-xl">Edit Biodata</span>}
            centered
            open={openModalUpdateProfile}
            footer={null}
            width="450px"
            closable={false}
        >
            <div>
                {
                    errors?.error && (
                        <div className="sl-flex sl-justify-center sl-text-text-error sl-border sl-border-text-error sl-rounded-md sl-p-2">
                            {errors?.error}
                        </div>
                    )
                }
                <div className="sl-space-y-3 sl-my-5">
                    <div>
                        <Label text="Name" />
                        <Input
                            placeholder="Name"
                            type='text'
                            name='name'
                            size="large"
                            value={formData?.name}
                            onChange={handleChangeUser}
                        />
                        {errors?.name && (
                            <div className="sl-w-full sl-justify-start sl-items-start sl-flex">
                                <span className="sl-text-text-error sl-text-xs sl-mt-1">{errors?.name}</span>
                            </div>
                        )}
                    </div>
                    <div>
                        <Label text="Email" />
                        <Input
                            placeholder="Email"
                            type='email'
                            name='email'
                            size="large"
                            value={formData?.email}
                            onChange={handleChangeUser}
                            onBlur={validateEmail}
                        />
                        {errors?.email && (
                            <div className="sl-w-full sl-justify-start sl-items-start sl-flex">
                                <span className="sl-text-text-error sl-text-xs sl-mt-1">{errors?.email}</span>
                            </div>
                        )}
                    </div>
                    
                    <div>
                        <Label text="Phone" />
                        <Input
                            placeholder="Phone"
                            type='text'
                            name='phone'
                            size="large"
                            value={formData?.phone}
                            onChange={(e) => {
                                const value = e.target.value;
                                // Allow only numeric characters
                                if (/^\d*$/.test(value)) {
                                handleChangeUser(e); // Update only if valid
                                }
                            }}
                        />
                        {errors?.phone && (
                            <div className="sl-w-full sl-justify-start sl-items-start sl-flex">
                                <span className="sl-text-text-error sl-text-xs sl-mt-1">{errors?.phone}</span>
                            </div>
                        )}
                    </div>
                    
                </div>
                <div className="sl-pt-4 sl-flex sl-gap-2 sl-justify-end">
                    <Button
                        className="sl-font-semibold"
                        onClick={handleClose}
                    >
                        Batal
                    </Button>
                    <Button 
                        disabled={loading}
                        onClick={handleSubmitUser}
                        className="sl-font-semibold sl-bg-button-primary sl-text-white"
                    >
                        Simpan
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalUpdateProfile;
