export class Config {
  static REACT_APP_API_BASE_URL = window.REACT_APP_API_BASE_URL;

  static REACT_APP_SENTRY_DSN = window.REACT_APP_SENTRY_DSN;
  static REACT_APP_SENTRY_ENV = window.REACT_APP_SENTRY_ENV;
  static REACT_APP_SENTRY_RELEASE = window.REACT_APP_SENTRY_RELEASE;
  static REACT_APP_SENTRY_ENV = window.REACT_APP_SENTRY_ENV;
  static REACT_APP_SENTRY_TRACE_SAMPLE_RATE =
    window.REACT_APP_SENTRY_TRACE_SAMPLE_RATE;
  static REACT_APP_SENTRY_SESSION_SAMPLE_RATE =
    window.REACT_APP_SENTRY_SESSION_SAMPLE_RATE;
  static REACT_APP_SENTRY_ON_ERROR = window.REACT_APP_SENTRY_ON_ERROR;
}
