import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import { Modal, Table } from "antd"
import { DownOutlined, UpOutlined } from "@ant-design/icons"
import InfoCircleIcon from "../../assets/icons/InfoCircleIcon.js"
import DownloadIcon from "../../assets/icons/DownloadIcon.js"
import { useParams } from "react-router-dom"
import { toCurrency, toCurrencyV2 } from "../../utils/String.js"
import { useQuery } from "react-query"
import "./Evaluation.css"
import { getDetailEvaluation } from '../../api/Evaluation.js'
import _ from "lodash"

export default function EvaluationDetail() {
    const { id } = useParams();

    const [changesModal, setChangesModal] = useState(false);
    const [changesData, setChangesData] = useState("");
    const [dataBillEstimation, setDataBillEstimation] = useState([]);
    const [dataActualInvoice, setDataActualInvoice] = useState([]);
    const [expandedRowKeysBillEstimation, setExpandedRowKeysBillEstimation] = useState([]);
    const [expandedRowKeysActualInvoice, setExpandedRowKeysActualInvoice] = useState([]);

    const [file, setFile] = useState({
        filename: "",
        path: ""
    });

    const { data: dataDetail } = useQuery(
        ['getHeaderEvaluation', id],
        () => getDetailEvaluation(id),
        {
            enabled: true,
        }
    );

    const openChangesModal = (data) => {
        setChangesData(data)
        setChangesModal(true)
    };

    const closeChangesModal = () => {
        setChangesModal(false)
        setChangesData("")
    };

    const handleDownloadFile = () => {
        if (file?.path) {
            window.open(file.path, "_blank")
        } else {
            console.error("File path is invalid")
        }
    };

    const handleExpand = (expanded, record) => {
        if (expanded) {
            setExpandedRowKeysBillEstimation((prev) => [...prev, record.key])
        } else {
            setExpandedRowKeysBillEstimation((prev) => prev.filter((pre) => pre !== record.key))
        }
    };
    
    const handleExpandActualInvoice = (expanded, record) => {
        if (expanded) {
            setExpandedRowKeysActualInvoice((prev) => [...prev, record.key])
        } else {
            setExpandedRowKeysActualInvoice((prev) => prev.filter((pre) => pre !== record.key))
        }
    };

    const columnsBillEstimation = [
        {
            title: "No",
            dataIndex: "index",
            width: "61px",
            key: "index"
        },
        Table.EXPAND_COLUMN,
        {
            title: "Nama Biaya",
            dataIndex: "alias",
            key: "alias",
            width: "40%",
            render: (_, record) => {
                return (
                    <div>
                        <p>{record.alias}</p>
                    </div>
                )
            }
        },
        {
            title: "Amount",
            dataIndex: "total_amount",
            key: "total_amount",
            align: "right",
            width: "25%",
            render: (_, record) => (
                <div className="sl-flex sl-items-center sl-gap-2 sl-justify-end">
                    {record.isUpdated && (
                        <button onClick={() => openChangesModal(record)}>
                            <InfoCircleIcon />
                        </button>
                    )}
                    {toCurrency(record.total_amount)}
                </div>
            )
        },
        {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
            align: "left",
            width: "25%",
        }
    ];
    
    const columnsActualInvoice = [
        {
            title: "No",
            dataIndex: "index",
            width: "61px",
            key: "index"
        },
        Table.EXPAND_COLUMN,
        {
            title: "Nama Biaya",
            dataIndex: "alias",
            key: "alias",
            render: (_, record) => {
                return (
                    <div>
                        <p>{record.category_alias}</p>
                    </div>
                )
            }
        },
        {
            title: "Amount",
            dataIndex: "total_amount",
            key: "total_amount",
            align: "right",
            render: (_, record) => (
                <div className="sl-flex sl-items-center sl-gap-2 sl-justify-end">
                    {record.isUpdated && (
                        <button onClick={() => openChangesModal(record)}>
                            <InfoCircleIcon />
                        </button>
                    )}
                    
                    {record?.deviation && (record?.deviation >= 1 || record?.deviation <= 0) ? (
                        <div className={`${record?.deviation > 0 ? "sl-text-text-success" : "sl-text-[#B91C1C]"}`}>({toCurrencyV2(record?.deviation)})</div>
                    ) : ""} 
                    {toCurrency(record.amount)}
                </div>
            )
        }
    ];

    useEffect(() => {
        if (dataDetail) {
            const transformedData = dataDetail?.data?.invoice?.details
                .map((categorie, i) => ({
                    ...categorie,
                    key: i + 1,
                    id: i + 1,
                }))
                .sort((a, b) => a.id - b.id) // Sort by `id` field
                .map((item, index) => ({
                    ...item,
                    index: index + 1, // Add `index` starting from 1
                }));
            setDataActualInvoice(transformedData);
        } else {
            setDataActualInvoice([]); // Fallback if categories are undefined
        }

        if (dataDetail) {
            const transformedData = dataDetail?.data?.price_estimation?.categories
                .map((categorie, i) => ({
                    ...categorie,
                    key: i + 1,
                    id: i + 1,
                }))
                .sort((a, b) => a.id - b.id) // Sort by `id` field
                .map((item, index) => ({
                    ...item,
                    index: index + 1, // Add `index` starting from 1
                }));
            setDataBillEstimation(transformedData);
        } else {
            setDataBillEstimation([]); // Fallback if categories are undefined
        }

        if (dataDetail) {
            // setFile(dataDetail?.data)
            setExpandedRowKeysBillEstimation(dataDetail?.data?.price_estimation?.categories?.map((d,i)=>(i+1)));
            setExpandedRowKeysActualInvoice(dataDetail?.data?.invoice?.details?.map((d,i)=>(i+1)));
        }

    }, [dataDetail]);

    let invoice_deviation = _.get(dataDetail, "data.invoice.deviation")
    return (
        <div className="sl-w-full sl-min-w-[500px] sl-max-w-[1600px] sl-py-12 sl-p-10 sl-space-y-7 sl-px-[auto] sl-mx-[auto]">
            <div className="sl-flex sl-justify-between sl-gap-4 sl-mb-6">
                <div className="sl-h-32 sl-w-1/3 sl-flex sl-flex-col sl-justify-center sl-border sl-border-[lightgray] sl-rounded-lg sl-p-6">
                    <p className="sl-text-[gray]">Total Bill Estimation</p>
                    <p className="sl-text-3xl sl-font-semibold">
                        {toCurrency(dataDetail?.data?.total_price_estimation) || "-"} 
                    </p>
                </div>
                <div className="sl-h-32 sl-w-1/3 sl-flex sl-flex-col sl-justify-center sl-border sl-border-[lightgray] sl-rounded-lg sl-p-6">
                    <p className="sl-text-[gray]">Total Actual Invoice</p>
                    <p className="sl-text-3xl sl-font-semibold">
                        {toCurrency(dataDetail?.data?.total_invoice) || "-"} 
                    </p>
                </div>
                <div className="sl-h-32 sl-w-1/3 sl-flex sl-flex-col sl-justify-center sl-border sl-border-[lightgray] sl-rounded-lg sl-p-6">
                    <p className="sl-text-[gray]">Deviation</p>
                    <p className={`sl-text-3xl sl-font-semibold ${dataDetail?.data?.total_deviation >= 0 ? "sl-text-text-success" : "sl-text-[#B91C1C]"}`}>
                        {toCurrency(dataDetail?.data?.total_deviation) || "-"} ({dataDetail?.data?.deviation_percentage}%)
                    </p>
                </div>
            </div>

            <div className="sl-border sl-border-border-primary-light sl-rounded sl-p-4 sl-space-y-4">
                <div className="sl-grid sl-grid-cols-4">
                    <div className="sl-flex sl-flex-col">
                        <div className="sl-text-text-secondary">Invoice No</div>
                        <div className="sl-text-text-primary sl-font-semibold">
                            {dataDetail?.data?.invoice?.invoice_no || "-"} 
                        </div>
                    </div>
                    <div className="sl-flex sl-flex-col">
                        <div className="sl-text-text-secondary">Invoice Date</div>
                        <div className="sl-text-text-primary sl-font-semibold">
                            {dayjs(dataDetail?.data?.invoice?.invoice_date).format("DD MMM YYYY, HH:mm") || "-"} 
                        </div>
                    </div>
                    <div className="sl-flex sl-flex-col">
                        <div className="sl-text-text-secondary">Admission Date</div>
                        <div className="sl-text-text-primary sl-font-semibold">
                            {dayjs(dataDetail?.data?.invoice?.admission_date).format("DD MMM YYYY, HH:mm") || "-"} 
                        </div>
                    </div>
                    <div className="sl-flex sl-flex-col">
                        <div className="sl-text-text-secondary">Discharge</div>
                        <div className="sl-text-text-primary sl-font-semibold">
                            {dayjs(dataDetail?.data?.invoice?.discharge_date).format("DD MMM YYYY, HH:mm") || "-"} 
                        </div>
                    </div>
                </div>
            </div>
            <div className="sl-border sl-border-border-primary-light sl-rounded sl-p-4 sl-space-y-4">
                <div className="sl-mb-4">
                    <div className="sl-grid sl-grid-cols-4 sl-gap-x-1">
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Electronic Medic Record</div>
                            <div className="sl-text-text-primary sl-font-semibold">
                                {dataDetail?.data?.price_estimation?.medical_record_no || "-"} 
                            </div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Nama Pasien</div>
                            <div className="sl-text-text-primary sl-font-semibold">
                                {dataDetail?.data?.price_estimation?.patient || "-"} 
                            </div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Nama Prosedur</div>
                            <div className="sl-text-text-primary sl-font-semibold">
                                {dataDetail?.data?.price_estimation?.procedure_name || "-"} 
                            </div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Nama Dokter Operator</div>
                            <div className="sl-text-text-primary sl-font-semibold">
                                {dataDetail?.data?.price_estimation?.doctor_name || "-"} 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sl-mb-4">
                    <div className="sl-grid sl-grid-cols-4">
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Tanggal Operasi</div>
                            <div className="sl-text-text-primary sl-font-semibold">
                                {dayjs(dataDetail?.data?.price_estimation?.operation_date).format("DD MMM YYYY")},{" "}
                                {dataDetail?.data?.price_estimation?.operation_start_time?.split(":")[0]}:
                                {dataDetail?.data?.price_estimation?.operation_start_time?.split(":")[1]} -{" "}
                                {dataDetail?.data?.price_estimation?.operation_end_time?.split(":")[0]}:
                                {dataDetail?.data?.price_estimation?.operation_end_time?.split(":")[1]}
                            </div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Kelas/Kategori</div>
                            <div className="sl-text-text-primary sl-font-semibold">
                                {dataDetail?.data?.price_estimation?.table_category || "-"} 
                            </div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Jenis Anestesi</div>
                            <div className="sl-text-text-primary sl-font-semibold">
                                {dataDetail?.data?.price_estimation?.anesthesia_type || "-"} 
                            </div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Admission Type</div>
                            <div className="sl-text-text-primary sl-font-semibold">
                                {dataDetail?.data?.price_estimation?.anesthesia_type || "-"} 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sl-mb-4">
                    <div className="sl-grid sl-grid-cols-4">
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Sifat Operasi</div>
                            <div className="sl-text-text-primary sl-font-semibold">
                                {dataDetail?.data?.price_estimation?.nature_operation || "-"} 
                            </div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Penanggung Jawab</div>
                            <div className="sl-text-text-primary sl-font-semibold">
                                {dataDetail?.data?.price_estimation?.payment_type || "-"} 
                            </div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Kelas Perawatan</div>
                            <div className="sl-text-text-primary sl-font-semibold">
                                {dataDetail?.data?.price_estimation?.service_class || "-"} 
                            </div>
                        </div>
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Keterangan</div>
                            <div className="sl-text-text-primary sl-font-semibold">
                                {dataDetail?.data?.price_estimation?.note || "-"} 
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sl-mb-4">
                    <div className="sl-grid sl-grid-cols-4">
                        <div className="sl-flex sl-flex-col">
                            <div className="sl-text-text-secondary">Medical Notes</div>
                            <div>
                                <button
                                    className="sl-flex sl-gap-2 sl-cursor-pointer sl-text-[blue]"
                                    onClick={handleDownloadFile}
                                >
                                    Download <DownloadIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sl-w-full sl-flex sl-flex-wrap">
                <div className="sl-w-1/2 sl-pr-4">
                    <div className="sl-text-2xl sl-font-semibold ">Bill Estimasi</div>
                    <Table
                        pagination={false}
                        columns={columnsBillEstimation}
                        dataSource={dataBillEstimation}
                        className="items-table"
                        footer={() => (
                            <div className="sl-flex sl-justify-between sl-items-center sl-text-text-primary sl-font-semibold">
                                <div>Total Biaya Operasi</div>
                                <div>{toCurrency(dataDetail?.data?.price_estimation?.estimation)}</div>
                            </div>
                        )}
                        expandable={{
                            expandedRowRender: (record) => {
                                return (
                                <div className="sl-w-full sl-flex sl-flex-wrap sl-space-y-2">
                                    {record?.components?.map((coll, index) => (
                                        <div
                                            key={index}
                                            className="sl-w-full sl-flex sl-justify-around sl-items-center"
                                        >
                                            <div className="sl-w-1/2 sl-flex sl-flex-col sl-pl-[4rem] sl-text-xs sl-leading-[21px]">
                                                <div className="sl-flex sl-gap-1">
                                                    <span className="sl-font-medium sl-inline">{coll?.name} </span>
                                                </div>
                                                {/* Remove due to redundant reason 
                                                    https://codr.atlassian.net/jira/software/projects/CON/boards/72
                                                */}
                                                {/* <p className="sl-text-[darkgray]"> {coll?.reason}</p> */}
                                            </div>
                                            <div className="sl-w-1/2 sl-text-sm sl-flex sl-items-center">
                                                <div className="sl-flex sl-w-1/2 sl-justify-end sl-gap-1">
                                                    {coll?.update_reason && (
                                                        <button onClick={() => openChangesModal(coll, index)} >
                                                            <InfoCircleIcon />
                                                        </button>
                                                    )}
                                                    {toCurrency(coll?.amount)} ( {coll?.qty}x )
                                                </div>
                                                <div className="sl-flex sl-w-1/2 sl-pl-4">
                                                    {coll?.reason || "-"}
                                                </div>
                                               
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )},
                            rowExpandable: (record) => record?.components?.length > 0,
                            expandedRowKeysBillEstimation,
                            onExpand: handleExpand,
                            expandIcon: ({ expanded, onExpand, record }) => (
                                <>
                                    {record?.components?.length > 0 && (
                                        <span className="sl-cursor-pointer" onClick={(e) => onExpand(record, e)}>
                                            {expanded ? (
                                                <UpOutlined style={{ fontSize: "10px" }} />
                                            ) : (
                                                <DownOutlined style={{ fontSize: "10px" }} />
                                            )}
                                        </span>
                                    )}
                                </>
                            )
                        }}
                    />
                    <div className="sl-p-4 sl-bg-surface-primary-light sl-rounded sl-space-y-4 sl-mt-5">
                        <div className="sl-text-xs sl-text-text-primary">
                            {dataDetail?.data?.price_estimation?.summary?.split("\n").map((str) => {
                                const formattedStr = str.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
                                return <div className="sl-mt-3" dangerouslySetInnerHTML={{ __html: formattedStr }} />
                            })}
                        </div>
                    </div>
                </div>
                <div className="sl-w-1/2 sl-pr-4">
                    <div className="sl-text-2xl sl-font-semibold ">Actual Invoice</div>
                    <Table
                        pagination={false}
                        columns={columnsActualInvoice}
                        dataSource={dataActualInvoice}
                        className="items-table"
                        footer={() => (
                            <div className="sl-flex sl-justify-between sl-items-center sl-text-text-primary sl-font-semibold">
                                <div>Total Biaya Operasi</div>
                                <div className="sl-flex sl-gap-2">
                                    {invoice_deviation && (invoice_deviation >= 1 || invoice_deviation <= 0) ? (
                                        <div className={`${invoice_deviation > 0 ? "sl-text-text-success" : "sl-text-[#B91C1C]"}`}>({toCurrencyV2(invoice_deviation)})</div>
                                    ) : ""} 
                                    {toCurrency(dataDetail?.data?.invoice?.total)}
                                </div>
                            </div>
                        )}
                        expandable={{
                            expandedRowRender: (record) => (
                                <div className="sl-w-full sl-flex sl-flex-wrap sl-space-y-2">
                                    {record?.items?.map((coll, index) => (
                                        <div
                                            key={index}
                                            className="sl-w-full sl-flex sl-justify-around sl-items-center"
                                        >
                                            <div className="sl-w-1/2 sl-flex sl-flex-col sl-pl-[4rem] sl-text-xs sl-leading-[21px]">
                                                <div className="sl-flex sl-gap-1">
                                                    <span className="sl-font-medium sl-inline">{coll?.item_name} </span>
                                                </div>
                                                {/* <p className="sl-text-[darkgray]"> {coll?.reason}</p> */}
                                            </div>
                                            <div className="sl-w-1/2 sl-text-sm sl-flex sl-items-center sl-justify-end sl-gap-1">
                                                {coll?.update_reason && (
                                                    <button onClick={() => openChangesModal(coll, index)}>
                                                        <InfoCircleIcon />
                                                    </button>
                                                )}
                                                {toCurrency(coll?.amount)} ( {coll?.qty}x )
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ),
                            rowExpandable: (record) => record?.items?.length > 0,
                            expandedRowKeysActualInvoice,
                            onExpand: handleExpandActualInvoice,
                            expandIcon: ({ expanded, onExpand, record }) => (
                                <>
                                    {record.items.length > 0 && (
                                        <span className="sl-cursor-pointer" onClick={(e) => onExpand(record, e)}>
                                            {expanded ? (
                                                <UpOutlined style={{ fontSize: "10px" }} />
                                            ) : (
                                                <DownOutlined style={{ fontSize: "10px" }} />
                                            )}
                                        </span>
                                    )}
                                </>
                            )
                        }}
                    />
                    
                </div>
            </div>

            <Modal
                title={<div className="sl-text-xl">Detail Perubahan</div>}
                centered
                open={changesModal}
                onCancel={() => closeChangesModal()}
                footer={null}
                width="400px"
            >
                <div className="sl-space-y-3 sl-pt-4">
                    <div className="sl-grid sl-grid-cols-2">
                        <div>
                            <div className="sl-text-text-secondary">Edited By</div>
                            <div className="sl-text-text-primary sl-font-semibold">{changesData?.updated_by_name || "-"}</div>
                        </div>
                        <div>
                            <div className="sl-text-text-secondary">Waktu Perubahan</div>
                            <div className="sl-text-text-primary sl-font-semibold">{dayjs(changesData?.updated_at).format("DD MMM YYYY, HH:mm") || "-"}</div>
                        </div>
                    </div>

                    <div>
                        <div className="sl-text-text-secondary">Amount</div>
                        <div className="sl-text-text-primary sl-font-semibold">{toCurrency(changesData?.amount) || "-"}</div>
                    </div>

                    <div>
                        <div className="sl-text-text-secondary">Alasan Perubahan</div>
                        <div className="sl-text-text-primary sl-font-semibold">{changesData?.update_reason || "-"}</div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}