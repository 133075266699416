import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import ImageHospital from '../assets/images/landing_sembuh_ai.png'

const AuthTemplate = () => {
    return(
        <>
            <div className="sl-w-full sl-min-h-screen sl-flex sl-flex-wrap sl-bg-[#111827] sl-p-8">
                <div className="sl-w-3/5 sl-flex">
                    <div
                        className="sl-w-full sl-relative sl-bg-no-repeat"
                        style={{
                            backgroundImage: `url(${ImageHospital})`,
                            backgroundSize: '100% 100%',
                        }}
                    >
                        <div className="sl-w-full sl-absolute sl-inset-0 sl-bg-gradient-to-l  sl-from-[#111827] sl-to-transparent"></div>
                        <div className="sl-h-[150px] sl-absolute sl-inset-0 sl-bg-gradient-to-b  sl-from-[#111827] sl-to-transparent"></div>
                        
                        <div className="sl-absolute sl-inset-0 sl-flex sl-flex-col sl-justify-start sl-text-white sl-mx-20 sl-mt-16">
                            <span className="sl-text-3xl sl-font-semibold sl-mb-2">
                                "The only way to do great work is <br /> to love what you do."
                            </span>
                            <span className="sl-text-xl sl-font-light">Steve Jobs</span>
                            <span className="sl-text-sm sl-font-light">Former CEO of Apple</span>
                        </div>
                    </div>
                </div>
                <div className="sl-w-2/5 sl-flex sl-bg-white sl-rounded-lg">
                    <Suspense fallback={`Loading...`}>
                        <Outlet />
                    </Suspense>
                </div>
            </div>
        </>
    )
}

export default AuthTemplate;